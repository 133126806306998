import React, { useState, useEffect, useCallback } from 'react'
import {
	SafeAreaView,
	StyleSheet,
	View,
	Platform,
	ScrollView,
	useWindowDimensions,
	Text, Pressable, Linking, Button, Image
} from 'react-native'

import {
	Provider as PaperProvider,
	Card,
	Title,
	Paragraph,
	ActivityIndicator,
	TextInput,
	Portal,
	Dialog
	
} from 'react-native-paper'

//import { FontAwesome } from '@expo/vector-icons'; // **NEW: Import icon library for the star**
//import FontAwesome from 'react-native-vector-icons/FontAwesome';
import CryptoJS from 'crypto-js';
import { signInAnonymously } from 'firebase/auth'
import { storage, auth, appCheckInstance } from './firebaseConfig'
import { ref, getDownloadURL, getMetadata } from 'firebase/storage'
import { getToken } from 'firebase/app-check'
import SearchAndFilterPanel from './components/SearchAndFilterPanel';
import Header from './components/Header'
//import BouncyCheckbox from 'react-native-bouncy-checkbox';

// Add this custom hook after your existing imports
const useResponsiveLayout = () => {
	const { width } = useWindowDimensions();
	
	const getColumnCount = () => {
	  if (Platform.OS !== 'web') return 3;
	  if (width >= 1800) return 6;      // 20% width
	  if (width >= 1200) return 5;      // 25% width
	  if (width >= 768) return 3;       // 33.33% width
	  return 2;                         // 50% width
	};
  
	return {
	  columnCount: getColumnCount(),
	  isDesktopMode: width >= 1024,
	  width
	};
};

const loginUser = async () => {
	try {
	  await signInAnonymously(auth);
	  console.log('User logged in anonymously');
	} catch (error) {
	  console.error('Error with anonymous login:', error);
	}
  };
  
const appCheckToken = await getToken(appCheckInstance, true);

const imagePath = process.env.REACT_APP_FIREBASE_STORAGE_DLD_IMAGE_PATH;
//const backupImage = process.env.REACT_APP_LOCAL_BACKUP_IMAGE_PATH;
const backupImage = require('./assets/fithealth/fithealth-icon.png');

// Error handling component
const ErrorDialog = ({ visible, message, onDismiss, onRetry }) => (
	<Portal>
		<Dialog visible={visible} onDismiss={onDismiss}>
			<Dialog.Title>Error</Dialog.Title>
			<Dialog.Content>
				<Paragraph>{message}</Paragraph>
			</Dialog.Content>
			<Dialog.Actions>
				<Button onPress={onRetry}>Retry</Button>
				<Button onPress={onDismiss}>Dismiss</Button>
			</Dialog.Actions>
		</Dialog>
	</Portal>
)


const formatPrice = (price) => {
	// Check if price is a number and not zero, or a non-empty string
	if ((typeof price === 'number' && price !== 0) || (typeof price === 'string' && price.trim() !== '' && price !== '0' && price !== '0.0' && price !== 'NA') ) {
	  // If it's a number, format it to 2 decimal places
	  	let formattedPrice = typeof price === 'number' ? price.toFixed(2) : price.trim();
    // Check if the formatted price already starts with a dollar sign
    	if (!formattedPrice.startsWith('$') && !/^[a-zA-Z]/.test(formattedPrice)) {
			formattedPrice = `$${formattedPrice}`;
	  	}
		return formattedPrice;

	}
	return null; // Return null if price is 0 or empty string or NA 
};

// UPDATE: NOT USED - To check value of "Product New" json obj and return text
const formatIsNew = (isNew) => {

	if ( isNew === 'YES') {
		return (
			//<View style={styles.isNewStarcontainer}>
			<View>
				<Image
			  		source={require('./assets/new-product-logopic.png')}
			  		style={styles.isNewstarImage}
				/>
		  </View>
		);
	}
	return null; // Add this line to handle cases when isNew !== 'YES'

};

// To extract float price from string containing other text e.g "Sale: $10"
const extractPrice = (priceInput) => {
	let priceString;
  
	// Check the type of priceInput and convert to string if necessary
	if (typeof priceInput === 'number') {
	  // If it's a number, convert it directly to a string
	  priceString = priceInput.toString();
	} else if (typeof priceInput === 'string') {
	  // If it's already a string, use it as is
	  priceString = priceInput;
	} else {
	  // If it's neither, return null or 0
	  return null; // or return 0;
	}
  
	// Use regex to extract numbers (including decimals)
	const numberString = priceString.match(/[\d,.]+/g);
  
	// If no numbers found, return null or 0
	if (!numberString) {
	  return null; // or return 0;
	}
  
	// Join the array of numbers (in case there are multiple matches)
	const numericValue = numberString.join('');
  
	// Replace commas with empty string (if using comma as thousand separator)
	const sanitizedValue = numericValue.replace(/,/g, '');
  
	// Convert to float
	const floatValue = parseFloat(sanitizedValue);
  
	// Check if the value is a valid number
	if (isNaN(floatValue)) {
	  return null; // or return 0;
	}
  
	// Return the float value rounded to 2 decimal places
	return parseFloat(floatValue.toFixed(2));
};


function generateHash(inputString, length = 10) {
	let md5Hash = CryptoJS.MD5(inputString).toString();
	return md5Hash.slice(0, length);
}
  
function generateFilename(categoryStore, productName, hashStr) {
	let cleanName = productName.replace(/[^a-zA-Z0-9\s-]/g, '').toLowerCase().trim().replace(/\s+/g, '-');

	//console.log(`cleanName:`, cleanName)

	let namePart = cleanName.slice(0, 10);

	//console.log(`namePart:`, namePart)
	//console.log(`imgefile name: ${categoryStore}-${namePart}-${hashStr}.png`)

	return `${categoryStore}-${namePart}-${hashStr}.png`;
}


const ProductCard = ({ product, isSelected, onSelect }) => {

	const { isDesktopMode } = useResponsiveLayout();

	const [imageUrl, setImageUrl] = useState(null);

	useEffect(() => {
	const fetchImageUrl = async () => {
		if (product['Product Image']) {
			try {
				const appCheckToken = await getToken(appCheckInstance, true);
				const hashStr = generateHash(product['Product Image']);
				const filename = generateFilename(`${product.category}-${product.vendorName}`, product['Product Name'], hashStr);
				//console.log(`In productcard useeffct; filename:`, filename)
				//console.log(`In productcard useeffct; imagePath:`, imagePath)

				const imageRef = ref(storage, `${imagePath}/${product.category}-${product.vendorName}/${filename}`);
				//console.log(`In productcard useeffct; imageRef:`, imageRef)

				//const metadata = await getMetadata(imageRef);
				//console.log(`image metadata`, metadata)
				//if (metadata) {
				await getMetadata(imageRef)
				const url = await getDownloadURL(imageRef, {
						customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
				});
					//console.log(`In productcard useeffct; url:`, url)
				setImageUrl(url);
			} catch (error) {
				if (error.code === 'storage/object-not-found') {
					//console.log(`image access fail error code object-not-found`, backupImage)
					setImageUrl(backupImage);
				} else {
					//console.error('Error checking image:', error);
					setImageUrl(backupImage);
				}			
			}
		} else {
			setImageUrl(backupImage); // fallback to placeholder
		}
	};

	fetchImageUrl();
	}, [product]);

	if (!product['Product Name']) return null

	const handlePress = () => {
		if (product['Product Url']) {
			Linking.openURL(product['Product Url']);
		} else {
			console.warn('No URL available for this product');
		}
	};

	//console.log(`image url after fetchimageurl`, imageUrl)
	return (
		<Pressable onPress={handlePress}>

		<Card style={[
			styles.card,
			isSelected && styles.selectedCard,
			isDesktopMode && styles.desktopCard
		]}>
			<View style={styles.cardHeader}>

				<Card.Cover
					//source={imageUrl ? { uri: imageUrl } : require('./assets/bf2024_all/blackfriday-128x128.png')} 
					//source={imageUrl ? { uri: imageUrl } : require(backupImage)}
					source={imageUrl ? { uri: imageUrl } : { uri: backupImage }}

					style={styles.cardImage}
				/>

                    {/* New Product Badge */}
                    {product["Product New"] === 'YES' && (
                        <Image 
                            source={require('./assets/startburst-newtext.png')}
							style={{
								position: 'absolute', 
								top: 0, 
								left:0,
								margin: 8,  // Optional: adds a small margin from the edges

								//right: 10, 
								width: 50,  // Adjust size as needed
								height: 50, // Adjust size as needed
								resizeMode: 'contain'
							}}
                        />
                    )}
					<View style={styles.priceContainer}>

					<View style={styles.priceContent}>
						{formatPrice(product["Product Price"]) && (

							<Text style={styles.price}>{product['Product Price']}</Text>
						)}
						{formatPrice(product['Product Original Price']) && (
							<View style={styles.discountBadge}>
								<Text style={styles.originalPrice}>
									{product['Product Original Price']}
								</Text>
							</View>
						)}
						{formatPrice(product['Product Discount']) && (
							<View style={styles.discountBadge}>
								<Text style={styles.discountText}>Discount:</Text>
								<Text style={styles.discountText}>
									{formatPrice(product['Product Discount'])}
								</Text>
							</View>
						)}

					</View>
				</View>
			</View>
			<Card.Content>
				<Title numberOfLines={1} style={styles.vendorName}>
					{product.vendorName}
				</Title>

				<Title numberOfLines={2} style={styles.productName}>
					{product['Product Name']}
				</Title>
				{product['Product Description'] &&
					product['Product Description'] !== 'NA' && (
						<Paragraph numberOfLines={2} style={styles.description}>
							{product['Product Description']}
						</Paragraph>
				)}
				{product['Product Review'] &&
					product['Product Review'] !== 'NA' && (
						<Paragraph numberOfLines={1} style={styles.review}>
							{product['Product Review']}
						</Paragraph>
				)}
			</Card.Content>

		</Card>
		</Pressable>

	)
}


const App = () => {
	const { columnCount, isDesktopMode, width } = useResponsiveLayout();

	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(true)
	const [filteredProducts, setFilteredProducts] = useState([]);

	const [error, setError] = useState(null)

	const [filters, setFilters] = useState({
		vendor: [],    
		category: [],  
		subCategory: [],  // New attribute for sub-category
		maxPrice: '',
		new: 'All'  // New filter with 'All' as default
	});


	const fetchProductData = useCallback(async () => {
		setLoading(true)
		setError(null)

		try {
			// Firebase Authentication
			await signInAnonymously(auth)
			const appCheckToken = await getToken(appCheckInstance, true)

			const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH
			const indexRef = ref(storage, indexPath)
			const indexUrl = await getDownloadURL(indexRef, {
				customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
			})
			//console.log(`indexUrl:`, indexUrl)
			const indexResponse = await fetch(indexUrl, {
				mode: 'cors',
				headers: { 'X-Firebase-AppCheck': appCheckToken.token }
			})

			if (!indexResponse.ok) {
				throw new Error(`HTTP error! status: ${indexResponse.status}`)
			}

			const indexData = await indexResponse.json()
			const productPromises = indexData.files.map(async filePath => {
				const category = filePath.split('/')[3];
				const vendorName = filePath.split('/')[4];
				//console.log(`category`, category)
				//console.log(`vendorName`, vendorName)
				//console.log(`filters.category`, filters.category)
				//console.log(`filters.vendor`, filters.vendor)
				
				// Only fetch products if they match the selected filter
				if (
				  	(filters.category.length === 0 || filters.category.includes(category)) &&
				  	(filters.vendor.length === 0 || filters.vendor.includes(vendorName))
				) 	{				
					try {
						const fileRef = ref(storage, filePath)
						const fileUrl = await getDownloadURL(fileRef, {
							customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
						})
						const response = await fetch(fileUrl, {
							mode: 'cors',
							headers: { 'X-Firebase-AppCheck': appCheckToken.token }
						})

						if (!response.ok) {
							throw new Error(`Failed to fetch ${filePath}`)
						}

						const products = await response.json()
						//console.log(`filepath:`, filePath)
						const vendorName = filePath.split('/')[4]
						//console.log(`category:`, filePath.split('/')[3])
						const category = filePath.split('/')[3]
						return products.map(product => {
							const subCategory = product['Product Category'] || ''; // Extract sub-category from JSON
							//console.log(`subcategory from json`, subCategory);
							return {
							  ...product,
							  vendorName,
							  category,
							  subCategory // Add sub-category consistently to product object
							};
						});
					} catch (error) {
						console.error(`Error fetching ${filePath}:`, error)
						return []
					}
				} else {
					return [];
				}					
			})


// Setting only filtered products instead of all products on initial page loads
			const filteredProducts = (await Promise.all(productPromises))
				.flat()
				.filter((product) => product['Product Name']);

			setProducts(filteredProducts);

		} catch (error) {
			console.error('Error fetching product data:', error)
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [])

	
	useEffect(() => {
		fetchProductData()
	}, [fetchProductData])

/*	Have a new updated useeffct for setting default filter below this
	useEffect(() => {
		const setDefaultFilters = async () => {
		  try {
			// Fetch initial metadata to determine available categories and vendors
			await signInAnonymously(auth);
			const appCheckToken = await getToken(appCheckInstance, true);
			const indexPath = process.env.REACT_APP_FIREBASE_STORAGE_INDEX_PATH;
			const indexRef = ref(storage, indexPath);
			const indexUrl = await getDownloadURL(indexRef, {
			  customHeaders: { 'X-Firebase-AppCheck': appCheckToken.token }
			});
			const indexResponse = await fetch(indexUrl, {
			  mode: 'cors',
			  headers: { 'X-Firebase-AppCheck': appCheckToken.token }
			});
	  
			if (!indexResponse.ok) {
			  throw new Error(`HTTP error! status: ${indexResponse.status}`);
			}
	  
			const indexData = await indexResponse.json();
			const categories = Array.from(new Set(indexData.files.map(filePath => filePath.split('/')[3])));
			const vendors = Array.from(new Set(indexData.files.map(filePath => filePath.split('/')[4])));
//			const subCategories = Array.from(new Set(products.flatMap(product => product['Product Category']).filter(Boolean)));
			const subCategories = Array.from(new Set(products.map(product => product.subCategory).filter(Boolean)));

			//console.log(`subcategory before setting filters`, subCategories)
			// Set default filters to the first available category, sub-category and vendor
			setFilters({
			  category: categories.length > 0 ? [categories[0]] : [],
			  vendor: vendors.length > 0 ? [vendors[0]] : [],
			  subCategory: subCategories.length > 0 ? [subCategories[0]] : [],  // Set default sub-category
			  maxPrice: ''
			});
			//console.log(`default subcat in useeffct before onfiltechange`, subCategories[0])
			//console.log(`all categories in useeffct before onfiltechange`, subCategories)
	  
		  } catch (error) {
			console.error('Error setting default filters:', error);
		  }
		};
	  
		setDefaultFilters();
	  }, []);
*/

	useEffect(() => {
		if (products.length > 0) {
		// Extract categories, vendors, and sub-categories after products are fetched
		const categories = Array.from(new Set(products.map((product) => product.category).filter(Boolean)));
		const vendors = Array.from(new Set(products.map((product) => product.vendorName).filter(Boolean)));
		const subCategories = Array.from(new Set(products.map((product) => product.subCategory).filter(Boolean)));
	
//		console.log(`subcategory before setting filters`, subCategories);
		
		// Set default filters to the first available category, vendor, and sub-category
		setFilters({
			category: categories.length > 0 ? [categories[0]] : [],
			vendor: vendors.length > 0 ? [vendors[0]] : [],
			subCategory: subCategories.length > 0 ? [subCategories[0]] : [],
			maxPrice: '',
            new: 'All'  // Explicitly set 'All'
		});
	
//		console.log(`default subcat in useEffect before onFilterChange`, subCategories[0]);
//		console.log(`all categories in useEffect before onFilterChange`, subCategories);
		}
	}, [products]); // This effect will run only after `products` has been updated
  

 	// Filtering Logic Updated for Array Handling
 	const filterProducts = (products, filters) => {
/*
		console.log('Filter Products Called with:', {
			products: products.length,
			filters: filters
		});
*/
		return products.filter(product => {
			const matchesVendor = filters.vendor.length === 0 || filters.vendor.includes(product.vendorName);
			const matchesCategory = filters.category.length === 0 || filters.category.includes(product.category);
			const matchesSubCategory = filters.subCategory.length === 0 || filters.subCategory.includes(product.subCategory);
//			console.log(`subcategories in filterProducts`, matchesSubCategory, filters.subCategory)
			const matchesPrice = !filters.maxPrice || extractPrice(product['Product Price']) <= parseFloat(filters.maxPrice);

        // New filter logic
        	const matchesNewFilter = filters.new === 'All' || 
            	(filters.new === 'New' && product['Product New'] === 'YES');

				//return matchesVendor && matchesCategory && 	matchesSubCategory && matchesPrice;
				//return matchesVendor && matchesCategory && 
				//	matchesSubCategory && matchesPrice && matchesNewFilter;

				const isMatch = matchesVendor && 
				matchesCategory && 
				matchesSubCategory && 
				matchesPrice && 
				matchesNewFilter;
 
/*				// Optional: Log details for each product that doesn't match
				if (!isMatch) {
					console.log('Product Filtered Out:', {
						productName: product['Product Name'],
						matchesVendor,
						matchesCategory,
						matchesSubCategory,
						matchesPrice,
						matchesNewFilter
					});
				}
*/		
				return isMatch;				
		});
		
  	};	

	// Update filtered products when filters change - update for searchfilter function, commented above line for this change
	useEffect(() => {
		const updatedFilteredProducts = filterProducts(products, filters);
		setFilteredProducts(updatedFilteredProducts);
	}, [filters, products]);
	//console.log(`In App.js, filters, product.category`, filters, products.vendorName)

	
	return (
		<PaperProvider>
			<SafeAreaView style={[
				styles.container,
				isDesktopMode && styles.desktopContainer
			]}>
			<Header/>
			<View style={[
          		styles.mainContent,
          		isDesktopMode && styles.desktopMainContent
        	]}>
 
				<View style={[
					styles.leftPanel,
					isDesktopMode && styles.desktopLeftPanel
				]}>
					<SearchAndFilterPanel 
						filters={filters} 
						onFilterChange={setFilters} 
						products={products} 
						setFilteredProducts={setFilteredProducts} 
					/>
				</View>

				<View style={[
    		        styles.rightPanel,
            		isDesktopMode && styles.desktopRightPanel
	          	]}>
					{loading ? (
						<View style={styles.loadingContainer}>
							<ActivityIndicator size='large' />
							<Text style={styles.loadingText}>Please wait..products loading!</Text>
						</View>
					) : filteredProducts.length === 0 ? (
						<View style={styles.loadingContainer}>
							<Text style={styles.loadingText}>No products available for current filter selections.</Text>
						</View>						
					) : (
						<ScrollView style={styles.productsContainer}>
							<View style={styles.productsGrid}>
								{filteredProducts.map((product, index) => (
									<View 		
										key={index} 
										style={[
											styles.productWrapper,
											{ width: `${100 / columnCount}%` }
//											{ width: `${100 / Math.min(columnCount, Math.floor(width / 200))}%` } // Dynamically ensure cards have enough width

										]}
										>
										<ProductCard
											product={product}
										/>
									</View>
								))}
							</View>
						</ScrollView>
					)}
				</View>
			</View>

			<ErrorDialog
				visible={!!error}
				message={error || 'An error occurred'}
				onDismiss={() => setError(null)}
				onRetry={fetchProductData}
			/>
			</SafeAreaView>
		</PaperProvider>
	)
}


const styles = StyleSheet.create({
	
	container: {
		flex: 1,
		backgroundColor: '#f5f5f5',
	},
	desktopContainer: {
		minWidth: 1024,
	},
	mainContent: {
		flex: 1,
		marginTop: 10,
		flexDirection: 'column',
	},
	desktopMainContent: {
		flexDirection: 'row',
	},
	leftPanel: {
		width: '100%',
		padding: 10,
		backgroundColor: '#ffffff',
	},
	desktopLeftPanel: {
		width: '20%',
		minHeight: '100vh',
		overflowY: 'auto',
	},
	rightPanel: {
		flex: 1,
		padding: 10,
		backgroundColor: '#f8f8f8',
	},
	desktopRightPanel: {
		minWidth: 768,
		overflowX: 'auto',
	},
	productsContainer: {
		flex: 1,
	},
	productsGrid: {
		flexDirection: 'row',
		flexWrap: 'wrap',
		padding: 8,
	},
	productWrapper: {
		padding: 8,
		minWidth: 200,
	},
	card: {
		height: '100%',
		backgroundColor: 'lightblue',
	},
	desktopCard: {
		minWidth: 200,
		maxWidth: 300,
	},
	selectedCard: {
		borderColor: 'gold',
		borderWidth: 2,
	},
	cardHeader: {
		flexDirection: 'row',
		justifyContent: 'space-between',
		alignItems: 'flex-start',
		padding: 8,
	},
 
	cardImage: {
		flex: 1,
		aspectRatio: 1, // Ensures the image maintains its original aspect ratio
		resizeMode: 'cover', // Makes sure the image covers the entire area without getting cut off
		width: '100%',
		height: undefined, // Allow the height to be dynamically calculated based on width and aspect ratio
		backgroundColor: 'lightblue',
	},

	priceWrapper: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center',
	},	
	priceContainer: {

		flex: 1,
		height: 100, // Match the height of cardImage
		justifyContent: 'center',
		alignItems: 'center',
	},
	priceContent: {
		alignItems: 'center',
	  },		
	price: {
		fontSize: 14,
		fontWeight: 'bold',
	},
	originalPrice: {
		fontSize: 12,
		textDecorationLine: 'line-through',
		color: '#fff'
	},
	discountBadge: {
		position: 'relative',
		top: 8,
		right: 8,
		backgroundColor: '#ff4444',
		padding: 4,
		borderRadius: 4,
		marginTop: 8,
	},
	discountText: {
		color: '#fff',
		fontSize: 10,
	},

 
	vendorName: {
		fontSize: 12,
		lineHeight: 15,
		fontWeight: 'bold'
	},

	productName: {
		fontSize: 12,
		lineHeight: 15,
		marginTop: 4,

	},
	description: {
		fontSize: 10,
		color: '#666',
		marginTop: 3,

	},
	review: {
		fontSize: 10,
		color: '#ff00ff',
		marginTop: 3,

	},

	loadingContainer: {
		flex: 1,
		justifyContent: 'center',
		alignItems: 'center'
	},
	loadingText: {
		marginTop: 16,
		fontSize: 24,
		fontWeight: 'bold',
		textAlign: 'center',
		color: 'red',
	},
	checkboxContainer: {
		alignItems: 'center',
		paddingVertical: 10,
	  },
})



export default App

